(function () {
	'use strict';
	angular
		.module('event')
		.controller('EventCtrl', [
			'$sce',
			'$scope',
			'$timeout',
			'$q',
			'seedcodeCalendar',
			'calendarIO',
			'utilities',
			'dataStore',
			'csvData',
			'environment',
			'manageResources',
			'interventions',
			'eventSettings',
			EventCtrl,
		]);

	function EventCtrl(
		$sce,
		$scope,
		$timeout,
		$q,
		seedcodeCalendar,
		calendarIO,
		utilities,
		dataStore,
		csvData,
		environment,
		manageResources,
		interventions,
		eventSettings
	) {
		var htmlDescriptionWatcher;

		//Load config from our model and watch for future updates
		$scope.calendar = {};
		$scope.calendar.config = seedcodeCalendar.get('config');

		//Load resources - Only load filtered resources here
		$scope.calendar.resources = manageResources.getFiltered();
		//Load statuses
		$scope.calendar.statuses = seedcodeCalendar.get('statuses');
		//Load schedules
		$scope.calendar.schedules = seedcodeCalendar.get('schedules');

		$scope.edit.isSalesforceSource = isSalesforceEvent();

		//Fade the content in
		$scope.edit.fade = false;

		$scope.edit.isShare = $scope.calendar.config.isShare;

		$scope.edit.locationIsURL = verifyURL($scope.edit.location);

		$scope.isPhone = environment.isPhone;

		// $scope.edit.repeatAllowed = $scope.edit.schedule && (  ($scope.edit.schedule.sourceTypeID===3  ||
		// 	(($scope.edit.schedule.objectName && $scope.edit.schedule.objectName==='Event') ||
		// 	($scope.edit.schedule.objectName && $scope.edit.schedule.objectName==='Task')) && $scope.edit.event.eventStatus && $scope.edit.schedule.sourceTypeID===4  )) ? true : false;

		if (
			$scope.popover.config.data.changed &&
			($scope.popover.config.data.changed.start ||
				$scope.popover.config.data.changed.end)
		) {
			$scope.repeatDateChanged = true;
			var originalRepeatEventDuration =
				$scope.popover.config.data.event.start.diff(
					$scope.popover.config.data.event.start,
					'days'
				);
		} else {
			$scope.repeatDateChanged = false;
		}

		// Playback
		if ($scope.edit.linkedEvents && $scope.edit.linkedEvents.playback) {
			playEvent();
		}

		updateRepeatAllowed();

		updateTodoAllowed();

		updateRelatedFields();

		updateHTMLDescription();

		initHTMLDescription();

		if ($scope.edit.defaultDrawer) {
			togglePanel($scope.popover.config.data.defaultDrawer);
		}

		$scope.edit.isRepeating = $scope.edit.recurringEventID ? true : false;

		function isSalesforceEvent() {
			if ($scope.edit?.event?.schedule) {
				return (
					$scope.edit.event.schedule.sourceTypeID === 4 ||
					$scope.edit.event.schedule.sourceTypeID === 10
				);
			}
			return false;
		}

		function updateRepeatAllowed() {
			$scope.edit.repeatingConfig =
				$scope.edit.schedule &&
				$scope.edit.schedule.source.repeatingConfig
					? $scope.edit.schedule.source.repeatingConfig()
					: false;
			$scope.edit.repeatAllowed =
				$scope.edit.repeatingConfig &&
				($scope.edit.repeatingConfig.schedulesEnabled === 'all' ||
					$scope.edit.repeatingConfig.schedulesEnabled[
						$scope.edit.schedule.objectName
					]) &&
				($scope.edit.repeatingConfig.createOnly
					? !$scope.edit.event.eventID || $scope.edit.recurringEventID
					: true)
					? true
					: false;

			if (!$scope.edit.repeatAllowed) {
				if (!$scope.edit.unused) {
					$scope.edit.unused = {};
				}
				$scope.edit.unused.repeatAllowed = true;
			}
		}

		function updateTodoAllowed() {
			var todoConfig;
			if ($scope.edit.sharedSource) {
				todoConfig =
					$scope.edit.sharedSource.sourceTemplate &&
					$scope.edit.sharedSource.sourceTemplate.todoConfig &&
					$scope.edit.sharedSource.allowTodo
						? $scope.edit.sharedSource.sourceTemplate.todoConfig()
						: null;
			} else {
				todoConfig =
					$scope.edit.schedule &&
					$scope.edit.schedule.source.todoConfig &&
					$scope.edit.schedule.allowTodo
						? $scope.edit.schedule.source.todoConfig()
						: null;
			}
			$scope.edit.todoConfig = todoConfig;
			$scope.edit.todoAllowed = todoConfig;
		}

		function updateHTMLDescription() {
			//Exit if our edit object doesn't contain the necessary info
			if (!$scope.edit.schedule && !$scope.edit.sharedSource) {
				return;
			}
			$scope.edit.htmlDescription = $scope.edit.sharedSource
				? $scope.edit.sharedSource.htmlDescription
				: $scope.edit.schedule.htmlDescription;
		}

		function updateRelatedFields() {
			//Exit if our edit object doesn't contain the necessary info
			if (!$scope.edit.schedule && !$scope.edit.sharedSource) {
				return;
			}
			if (
				$scope.edit.schedule.relatedValueMap &&
				$scope.edit.schedule.relatedValueMap.resourceID
			) {
				$scope.edit.resourceIsRelated = true;
			} else if ($scope.edit.resourceIsRelated) {
				$scope.edit.resourceIsRelated = false;
			}
		}

		//Watch for an event update broadcast
		$scope.$on('event', function () {
			var event = calendarIO.getDisplayEvent($scope.edit.eventID);
			calendarIO.updateEditEvent(event, $scope.edit);
			updateMap($scope.edit.location);
		});

		//Initialize map data - With delay so we don't slow showing popover
		window.setTimeout(function () {
			initMap();
		}, 500);

		//Initialize interventions
		// interventions.init($scope.edit.event.id, $scope.edit.start, $scope.edit.end, applyInterventions);

		$scope.editTimeDuration = getTimeDuration();

		$scope.navigatePanel = navigatePanel;

		$scope.listClick = listClick;

		$scope.onFieldSave = onFieldSave;

		$scope.deleteEvent = deleteEvent;

		$scope.matchArrayItem = matchArrayItem;

		$scope.customFieldList = customFieldList;

		$scope.picklist = picklist;

		$scope.scheduleSelectionValid = scheduleSelectionValid;

		$scope.hideField = hideField;

		$scope.updateMap = updateMap;

		$scope.toggleMap = toggleMap;

		$scope.locationEdit = locationEdit;

		$scope.locationSubmit = locationSubmit;

		$scope.openLocation = openLocation;

		$scope.saveTimeDuration = saveTimeDuration;

		$scope.togglePanel = togglePanel;

		$scope.closePanel = closePanel;

		$scope.repeatDialog = repeatDialog;

		$scope.toggleTodo = toggleTodo;

		$scope.panelBack = panelBack;

		$scope.updateIsDayback = updateIsDayback;

		$scope.getFieldHeight = getFieldHeight;

		$scope.addField = function () {
			eventSettings.addField($scope.edit);
		};

		$scope.addButton = function () {
			eventSettings.addButton($scope.edit);
		};

		$scope.emptyItemArray = emptyItemArray;

		$scope.customFieldDataExists = customFieldDataExists;

		$scope.customDatePicker = customDatePicker;

		$scope.selectCustomDate = selectCustomDate;

		$scope.toggleRadioButton = toggleRadioButton;

		$scope.viewEventLink = viewEventLink;

		$scope.playEvent = playEvent;

		$scope.pauseEvent = pauseEvent;

		$scope.navigateEvent = navigateEvent;

		$scope.openEditor = openEditor;

		function initHTMLDescription() {
			if (
				!htmlDescriptionWatcher &&
				($scope.edit.htmlDescription ||
					($scope.edit.allowHTML &&
						$scope.edit.allowHTML.description))
			) {
				htmlDescriptionWatcher = $scope.$watch(
					function () {
						return $scope.edit.description;
					},
					function (newValue, oldValue) {
						$scope.Html = $sce.trustAsHtml(
							$scope.edit.description
								? $scope.edit.description.toString()
								: ''
						);
					}
				);
			} else if (
				htmlDescriptionWatcher &&
				!$scope.edit.htmlDescription &&
				(!$scope.edit.allowHTML || !$scope.edit.allowHTML.description)
			) {
				// Remove watcher
				htmlDescriptionWatcher();
				htmlDescriptionWatcher = null;
			}
		}

		function initMap() {
			const config = seedcodeCalendar.get('config');
			const isURL = verifyURL($scope.edit.location);

			//Initialize map var
			$scope.map = {};
			if (isURL) {
				$scope.map.url = true;
				return;
			}

			if ($scope.edit.showMap === true || $scope.edit.showMap === false) {
				$scope.showMap = $scope.edit.showMap;
			} else {
				$scope.showMap = !!validateAddress($scope.edit.location);
			}
			if ($scope.showMap) {
				updateMap($scope.edit.location);
			}
		}

		function verifyURL(value) {
			let url;

			try {
				url = new URL(value);
			} catch (err) {
				return false;
			}

			return url.protocol === 'http:' || url.protocol === 'https:';
		}

		function toggleMap(show) {
			var location;
			if (
				$scope.map.error &&
				$scope.edit.location !== $scope.map.address
			) {
				$scope.map.error = false;
				$scope.map.address = null;
			}
			$scope.showMap = show;
			location = $scope.showMap ? $scope.edit.location : null;
			updateMap(location, show);
			$scope.edit.showMap = $scope.showMap;
		}

		function hideField(field, editObj) {
			return editObj.unused[field];
		}

		function updateMap(address, force) {
			var addressInfo = validateAddress(address);
			if (!addressInfo && !force) {
				$scope.showMap = false;
				address = null;
			} else {
				$scope.showMap = true;
			}
			//Trigger a rerender of the map
			$scope.map.zoom = addressInfo ? addressInfo.zoom : null;
			$scope.map.address = address;
		}

		function validateAddress(address) {
			var hasNumber;
			var hasComma;
			var result;

			if (!address) {
				return;
			}

			//Check if there is a comma. Used to detect city
			hasComma = address.indexOf(',') > -1;

			//Remove any non alpha numeric character.
			address = address.replace(/[^A-Za-z0-9\s!?]/g, '');
			//Split into an array using space as separator
			address = address.split(' ');

			for (var i = 0; i < address.length; i++) {
				if (!isNaN(address[i])) {
					hasNumber = true;
				}
			}
			if (address.length > 0 && hasComma && !hasNumber) {
				result = {
					type: 'city',
					zoom: 9,
				};
			} else if (address.length > 2 && hasNumber) {
				result = {
					type: 'street',
					zoom: 12,
				};
			}
			return result || false;
		}

		function locationEdit(address) {
			const isURL = verifyURL(address);
			if (isURL) {
				$scope.map.url = true;
				$scope.showMap = false;
				$scope.map.address = null;
				$scope.edit.locationIsURL = true;
			} else if ($scope.map.url) {
				$scope.map.url = false;
				$scope.edit.locationIsURL = false;
			}
			if ($scope.map.error) {
				$scope.map.error = false;
				$scope.map.address = null;
				$scope.showMap = false;
			}
		}

		function locationSubmit(e, address) {
			if (e && e.keyCode === 13) {
				const isURL = verifyURL(address);
				e.preventDefault();
				if (!isURL) {
					updateMap(address);
				}
			}
		}

		function openLocation(location) {
			if ($scope.edit.locationIsURL) {
				utilities.help(location, location, true);
				return;
			}

			const addressEncoded = encodeURIComponent(location);
			const url = 'https://www.google.com/maps/place/' + addressEncoded;
			utilities.help(url, url, true);
		}

		function customDatePicker(customFieldObj, format) {
			var field = customFieldObj.id;
			var dataFormat = customFieldObj.dataFormat;
			var formatAs = customFieldObj.formatas;

			if (dataFormat === 'datetime') {
				format = moment.ISO_8601;
			} else if (!format) {
				format =
					formatAs === 'timestamp' ? moment.ISO_8601 : 'MM/DD/YYYY';
			}

			var date = $scope.edit[field];
			$scope.edit.customFields[field].dateMoment = date
				? moment($scope.edit[field], format)
				: null;
			$scope.edit.secondPanelItemSelected =
				$scope.edit.customFields[field];
			$scope.edit.secondPanel = true;
		}

		function selectCustomDate(customFieldObj, format) {
			var field = customFieldObj.id;
			var date = customFieldObj.dateMoment;
			var dataFormat = customFieldObj.dataFormat;
			var formatAs = customFieldObj.formatas;
			var value;

			if (dataFormat === 'datetime') {
				format = ''; //Force iso by not providing a format
			} else if (!format && formatAs !== 'timestamp') {
				format = 'MM/DD/YYYY';
			}

			value = date.format(format);

			onFieldSave(
				$scope.edit,
				field,
				value,
				customFieldObj.name,
				null,
				null,
				update,
				null,
				revert
			);

			function update(reject) {
				if (reject) {
					return;
				}
				$timeout(function () {
					$scope.edit[field] = value;
					if (formatAs === 'date') {
						$scope.edit.secondPanel = null;
					}
				}, 0);
			}

			function revert() {
				$timeout(function () {
					$scope.edit.secondPanelItemSelected.dateMoment = moment(
						$scope.edit[field]
					);
				}, 0);
			}
		}

		function customFieldDataExists() {
			var customFields = $scope.edit.customFields;
			var edit = $scope.edit;

			if (!customFields) {
				return false;
			}

			for (var property in customFields) {
				if (Array.isArray(edit[property])) {
					if (edit[property].length) {
						return true;
					}
				} else if (edit[property]) {
					return true;
				}
			}
			return false;
		}

		function toggleRadioButton($event, field, key) {
			if (!$scope.edit.radioChanged[key]) {
				$scope.edit[field] = '';
			}
			$scope.edit.radioChanged[key] = false;
		}

		function viewEventLink(url) {
			utilities.help(url, url, true, false);
		}

		function saveTimeDuration(value) {
			$scope.editTimeDuration = getTimeDuration();
			var configMap = seedcodeCalendar.calendar.configMap;
			var saveID = configMap.editTimeDuration.saved;
			dataStore.saveState(saveID, value);
		}

		function togglePanel(panelName) {
			if (!panelName) {
				return;
			}
			if (
				$scope.edit.selectedPanelItem === panelName &&
				$scope.edit.showPanel
			) {
				$scope.closePanel();
			} else {
				$scope.edit.selectedPanelItem = panelName;
				$scope.edit.showPanel = true;
				if ($scope.edit && $scope.edit.secondPanel) {
					$scope.edit.secondPanel = null;
					$scope.edit.secondPanelItemSelected = null;
				}
			}
		}

		function closePanel() {
			$scope.edit.showPanel = false;
			$timeout(function () {
				if (!$scope.edit.showPanel) {
					$scope.edit.selectedPanelItem = null;
					$scope.edit.secondPanel = null;
					$scope.edit.secondPanelItemSelected = null;
				}
			}, 300);
		}

		function toggleTodo(value, schedule, preventRemoteCheck) {
			var saveDeferred = $q.defer();
			var editEvent = $scope.edit;
			if (value) {
				if (!schedule.lists || !schedule.lists.length) {
					if (!preventRemoteCheck) {
						editEvent.saveDeferred = saveDeferred.promise;
						//Check to see if there are any todo lists that we haven't retrieved yet
						schedule.source
							.todoConfig()
							.getTodoLists(schedule, function (todoLists) {
								//Run in async so we trigger a digest cycle
								$scope.$evalAsync(function () {
									toggleTodo(value, schedule, true);
									//Resolve our promise so the event can save if we want it to - Resolve after we set list in toggleTodo function
									saveDeferred.resolve();
								});
							});
						return;
					}
					editEvent.todo = false;
					var message =
						'There are currently no to-do lists for this project. Please create one in Basecamp and try again.';
					utilities.showModal(
						'To-Do List Required',
						message,
						'Cancel',
						null,
						'Create List In Basecamp',
						todoListURL
					);
					return;
				}
				editEvent.parentListID = schedule.lists[0].id;
				editEvent.parentListName = schedule.lists[0].name;
			} else {
				editEvent.parentListID = null;
				editEvent.parentListName = null;
			}

			function todoListURL() {
				var url = schedule.source
					.todoConfig()
					.getTodoListsURL(schedule);
				utilities.help(url, url, true);
			}
		}

		function repeatDialog() {
			var button;
			var message;

			$scope.edit.isRepeating = !$scope.edit.isRepeating;

			if (
				$scope.edit.repeatingConfig &&
				$scope.edit.repeatingConfig.createOnly &&
				!$scope.edit.isRepeating &&
				$scope.edit.recurringEventID
			) {
				//this schedule only allows editing of the rule on creation, we want to show the checkbox, but they can't uncheck it.
				//series exists in Google, so we'll challenge.
				message =
					'You can currently only edit the repeating rule on item creation or in Salesforce classic.';
				utilities.showModal(
					'Repeating Event',
					message,
					null,
					null,
					'ok',
					cancelButton
				);
				return;
			}

			if (!$scope.edit.isRepeating && !$scope.edit.recurringEventID) {
				//we've unchecked the box, but no series has been created on Google yet, we can just remove the recurrence
				$scope.edit.recurrence = $scope.edit.event.recurrence;
				return;
			} else if (
				!$scope.edit.isRepeating &&
				$scope.edit.recurringEventID
			) {
				//series exists in Google, so we'll challenge.
				message =
					'Would you like to delete all future repetitions and create new ones? This cannot be undone';
				utilities.showModal(
					'Repeating Event',
					message,
					'Cancel',
					cancelButton,
					'Delete Only',
					destroyOnlyButton,
					'Delete and New',
					destroyAndNewButton
				);
			} else if ($scope.edit.isRepeating) {
				openDialog();
			}

			function cancelButton() {
				$scope.edit.isRepeating = $scope.edit.isRepeating
					? false
					: true;
				$scope.edit.recurrence = $scope.edit.event.recurrence;
			}
			function destroyOnlyButton() {
				destroy();
			}
			function destroyAndNewButton() {
				button = 'newSeries';
				destroy(true);

				$timeout(function () {
					$scope.edit.isRepeating = true;
					openDialog();
				}, 1000);
			}

			function destroy(exclusiveStart) {
				var start = $scope.edit.event.start.clone();
				var untilMoment = start;

				if (exclusiveStart) {
					untilMoment.subtract(1, 'day');
				}

				$scope.edit.schedule.source.updateRepeatingEventUntil(
					$scope.edit,
					untilMoment,
					processRuleUpdate
				);
			}

			function processRuleUpdate(data) {
				var recurringEventID =
					data.recurringEventID || data.id || data.eventID;
				if (data.firstInstance) {
					//if this is the first instance, then just remove the rule
					$scope.edit.recurrence = [];
					const changes = {recurrence: []};
					$scope.edit.schedule.source.updateSourceEvent(
						$scope.edit,
						$scope.edit.event,
						null,
						changes,
						processRuleUpdate
					);
					$scope.edit.IsFirstInstance = true;
					return;
				}
				if ($scope.edit.IsFirstInstance) {
					$scope.edit.IsFirstInstance = false;
					$scope.edit.eventID = recurringEventID;
					$scope.edit.event.eventID = recurringEventID;
					$scope.edit.recurringEventID = '';
					$scope.edit.event.recurringEventID = '';
				} else if (button === 'newSeries') {
					$scope.edit.destroyToday = true;
					$scope.edit.eventID = null;
					$scope.edit.event.eventID = null;
					$scope.edit.recurringEventID = null;
					$scope.edit.event.recurringEventID = null;
					$scope.edit.isRepeating = true;
				} else {
					$scope.edit.isRepeating = true;
				}

				const saveArray = [];
				const element = seedcodeCalendar.get('element');
				const events = element.fullCalendar('clientEvents');
				for (var i = 0; i < events.length; i++) {
					if (
						events[i].recurringEventID === recurringEventID &&
						events[i].start.format('YYYYMMDD') >
							$scope.edit.start.format('YYYYMMDD')
					) {
						saveArray.push(events[i]);
						element.fullCalendar(
							'removeEvents',
							events[i]._id,
							events[i]
						);
					}
				}
				$scope.edit.savedRepetitons = saveArray;
				const message =
					'<span class="message-icon-separator success">' +
					'<i class="fa fa-lg fa-check"></i>' +
					'</span>' +
					'<span translate style="text-transform:capitalize;">' +
					'Future Repetitions Deleted' +
					'</span>';
				utilities.showMessage(message, 0, 5000);
			}

			function openDialog() {
				var template =
					'<div ng-include="\'app/repeat/repeat.html\'"></div>';
				var config = {
					controller: 'RepeatCtrl',
					container: document.querySelector('#calendar-container')
						? '#calendar-container'
						: '#app-container',
					type: 'modal', // modal or popover
					width: 380,
					destroy: true,
					confirmFunction: '',
					cancelFunction: '',
					data: $scope.edit,
					onShow: '',
					onShown: '',
					onHide: '',
					onHidden: '',
					show: true,
				};
				utilities.popover(config, template);
			}
		}

		function panelBack() {
			if ($scope.edit.secondPanel) {
				$scope.edit.contacts = null;
				$scope.edit.projects = null;
				$scope.edit.resources = null;
				$scope.edit.secondPanel = null;
				$timeout(function () {
					$scope.edit.secondPanelItemSelected = null;
				}, 500);
			} else {
				$scope.edit.showPanel = false;
				$timeout(function () {
					$scope.edit.selectedPanelItem = null;
				}, 500);
			}
		}

		function navigatePanel(value, navigate) {
			if (navigate) {
				$scope.edit.secondPanel = value;
				$timeout(function () {
					$scope.edit.secondPanelItemSelected = null;
				}, 500);
			}
		}

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		$scope.confirmSave = function () {
			$scope.popover.config.show = false;
			$scope.popover.config.confirmed = true;
		};

		$scope.confirmSaveFuture = function () {
			$scope.popover.config.future = true;
			$scope.popover.config.show = false;
			$scope.popover.config.confirmed = true;
		};

		$scope.confirmSaveAll = function () {
			$scope.popover.config.all = true;
			$scope.popover.config.show = false;
			$scope.popover.config.confirmed = true;
		};

		$scope.cancelSave = function () {
			$scope.popover.config.confirmed = false;
			$scope.popover.config.revert = true;
			$scope.popover.config.show = false;
		};

		$scope.toggleConfirmDelete = function () {
			$scope.confirmDelete = !$scope.confirmDelete;
		};

		$scope.selectCalendar = function (e, value, targetData, label) {
			onFieldSave(
				$scope.edit,
				'calendar',
				value,
				label,
				targetData,
				null,
				update,
				e
			);

			function update(reject) {
				if (reject) {
					return;
				}
				$timeout(function () {
					var schedules = seedcodeCalendar.get('schedules');
					var editable;
					for (var i = 0; i < schedules.length; i++) {
						if (value === schedules[i].id) {
							editable = scheduleSelectionValid(
								targetData,
								schedules[i]
							);
							if (editable) {
								changeCalendar(schedules[i]);
							}
						}
					}
					function changeCalendar(schedule) {
						$scope.edit.fade = true;
						var originalSchedule = $scope.edit.schedule;

						//If a focus action exists run it now
						if (schedule.source && schedule.source.eventFocus) {
							schedule.source.eventFocus(
								schedule,
								$scope.edit.event
							);
						}

						// If this is an unscheduled event but the source doesn't support it
						if (
							$scope.edit.unscheduled &&
							!schedule.allowUnscheduled
						) {
							$scope.edit.unscheduled = false;
						}

						//timeout is timed to match the css fade. This will always need to be in sync with css transition delay.
						$timeout(function () {
							if (schedule.allowAllDay === false) {
								$scope.edit.allDay = true;
							}

							if (!schedule.allowDayback) {
								$scope.edit.isDayback = false;
								updateIsDayback(
									$scope.edit.event,
									$scope.edit.isDayback,
									$scope.edit.tags
								);
							}

							if (schedule.fieldMap.done) {
								$scope.edit.done = true;
							}

							if (
								originalSchedule.defaultResource &&
								$scope.edit.isDefaultResource
							) {
								// Reset default resource
								$scope.edit.event.resource = [''];
								$scope.edit.event.isDefaultResource = false;
								calendarIO.assignDefaultResource(
									$scope.edit.event,
									$scope.edit,
									schedule
								);

								// // Reset default resource for event object
								// $scope.edit.event.resource = [""];
								// $scope.edit.event.isDefaultResource = false;
								// calendarIO.assignDefaultResource($scope.edit.event, schedule);
							} else if (schedule.defaultResource) {
								calendarIO.assignDefaultResource(
									$scope.edit.event,
									$scope.edit,
									schedule,
									true
								);
							}

							if (schedule.customFields) {
								//Remove old custom fields from event and edit event
								for (var property in originalSchedule.customFields) {
									if (
										!schedule.customFields.hasOwnProperty(
											property
										)
									) {
										delete $scope.edit[property];
										delete $scope.edit.event[property];
									}
								}
								//Add any custom fields related to new calendar
								calendarIO.assignCustomFields(
									$scope.edit.event,
									schedule.customFields
								);
								calendarIO.assignCustomFields(
									$scope.edit,
									schedule.customFields
								);
							}

							$scope.edit.unused = calendarIO.cloneMappedField(
								schedule.unusedMap,
								schedule.sourceTypeID,
								null
							);
							$scope.edit.allowHTML = calendarIO.cloneMappedField(
								schedule.allowHTMLMap,
								schedule.sourceTypeID,
								null
							);
							$scope.edit.hiddenField =
								calendarIO.cloneMappedField(
									schedule.hiddenFieldMap,
									schedule.sourceTypeID,
									null
								);
							$scope.edit.readOnlyField =
								calendarIO.cloneMappedField(
									schedule.readOnlyFieldMap,
									schedule.sourceTypeID,
									null
								);

							$scope.edit.eventSource = schedule.id;
							$scope.edit.customFields = schedule.customFields;
							$scope.edit.customActions = schedule.customActions;
							$scope.edit.labelMap = schedule.labelMap;

							$scope.edit.schedule = schedule;

							assignColor($scope.edit.status);
							updateRepeatAllowed();
							updateTodoAllowed();
							updateHTMLDescription();
							initHTMLDescription();
							updateRelatedFields();
							$scope.edit.fade = false;
						}, 200);
					}
				}, 0);
			}
		};

		$scope.selectParentList = function (e, value, label) {
			$scope.edit.parentListID = value;
			$scope.edit.parentListName = label;
		};

		$scope.selectStatus = function (e, value, targetData, label) {
			onFieldSave(
				$scope.edit,
				'status',
				value,
				label,
				targetData,
				null,
				update,
				e
			);

			function update(reject) {
				if (reject) {
					return;
				}
				$timeout(function () {
					var result = listClick(e, value, targetData);
					var labelResult = label
						? listClick(e, label, targetData, false)
						: result;
					$scope.edit.status = result;
					$scope.edit.statusLabel = labelResult;
					assignColor(result);
				}, 0);
			}
		};
		//LW
		$scope.selectResource = function (e, value, targetData, label) {
			return new Promise((resolve, reject) => {
				onFieldSave(
					$scope.edit,
					'resource',
					value,
					label,
					targetData,
					null,
					update,
					e
				);

				function update(rejected) {
					if (rejected) {
						reject();
						return;
					}
					$timeout(function () {
						if ($scope.edit.isDefaultResource) {
							$scope.edit.isDefaultResource = false;
							targetData = [];
						}
						var result = listClick(
							e,
							value,
							targetData,
							false,
							false
						);
						$scope.edit.resource = result;
						$scope.selectedResources = seedcodeCalendar
							.get('resources')
							.filter((resource) => {
								return matchArrayItem(
									resource.name,
									$scope.edit.resource
								);
							});
						resolve($scope.selectedResources);
					}, 0);
				}
			});
		};

		$scope.customFieldChanged = function (
			e,
			customField,
			value,
			previousValue,
			label,
			callback
		) {
			onFieldSave(
				$scope.edit,
				customField,
				value,
				label,
				null,
				null,
				update,
				e,
				revert
			);

			function update(reject) {
				if (reject) {
					return;
				}
				$timeout(function () {
					$scope.edit[customField] = value;
					if (
						$scope.edit.customFields[customField].formatas ===
						'radio'
					) {
						$scope.edit.radioChanged[customField] = true;
					} else if (
						$scope.edit.customFields[customField].formatas ===
							'date' &&
						value === ''
					) {
						$scope.edit.secondPanelItemSelected.dateMoment = value;
					}
					if (callback) {
						$timeout(function () {
							callback(value);
						}, 0);
					}
				}, 0);
			}
			function revert() {
				$timeout(function () {
					$scope.edit[customField] = previousValue;
					if (callback) {
						$timeout(function () {
							callback(previousValue, true);
						}, 0);
					}
				}, 0);
			}
		};

		$scope.isSameDate = function (date1, date2, limitBy) {
			//compares 2 dates to see if they are the same.
			//limitBy could be second, minute, hour, day, week, month, year and will limit the comparison to that level
			return date1.isSame(date2, limitBy);
		};

		$scope.showTimePicker = function () {
			if ($scope.edit.allDay) {
				return;
			} else if ($scope.edit.showTime) {
				$scope.edit.showTime = false;
			} else {
				$scope.edit.showTime = true;
			}
		};

		//filter the searchquery results to see if they match the list
		function resourceFilter(input, query) {
			if (!query || query === 'Show All') {
				return input;
			}
			var result = [];

			angular.forEach(input, function (resource) {
				if (compareStrings(resource.name, query)) {
					result.push(resource);
				}
			});

			function compareStrings(stra, strb) {
				stra = ('' + stra).toLowerCase();
				strb = ('' + strb).toLowerCase();

				return stra.indexOf(strb) !== -1;
			}
			return result;
		}

		//set defaults for search
		$scope.edit.resourceQuery = '';
		$scope.edit.lastResourceFilterQuery = '';
		$scope.resourceObjects = angular.copy($scope.calendar.resources);
		$scope.filteredResources = $scope.resourceObjects;
		const allResources = seedcodeCalendar.get('resources');

		$scope.selectedResources = seedcodeCalendar
			.get('resources')
			.filter((resource) => {
				return matchArrayItem(resource.name, $scope.edit.resource);
			});
		// $scope.filteredResources = angular.copy($scope.resourceObjects);

		//search on click
		$scope.searchResources = function (e, query) {
			if (
				e.keyCode === 13 ||
				e.keyCode === 9 ||
				e.keyCode === 10 ||
				!e.keyCode
			) {
				e.preventDefault();
				if (!$scope.edit.resourceQuery) {
					if (e.keyCode) {
						$scope.clearResourceFilter();
						return;
					}
					// Show all resources when clicking the filter button
					$scope.edit.resourceQuery = 'Show All';
				}
				$scope.filteredResources = resourceFilter(
					manageResources.getFiltered(true, 'resources'),
					$scope.edit.resourceQuery
				);
				$scope.edit.lastResourceFilterQuery = $scope.edit.resourceQuery;
			}
		};

		//reset resource list
		$scope.clearResourceFilter = function () {
			$scope.edit.resourceQuery = '';
			$scope.edit.lastResourceFilterQuery = '';
			$scope.filteredResources = $scope.resourceObjects;
		};

		$scope.findItems = function (
			e,
			type,
			object,
			searchField,
			displayField,
			findCriteria
		) {
			if (
				e.keyCode === 13 ||
				e.keyCode === 9 ||
				e.keyCode === 10 ||
				!e.keyCode
			) {
				e.preventDefault();
				//If we have no search criteria let's short circuit and return nothing.
				if (!findCriteria) {
					processData('');
					return false;
				}
				$scope.edit.searching = true;

				if (type === 'contacts') {
					$scope.edit.schedule.source.getContacts(
						processData,
						object,
						searchField,
						displayField,
						findCriteria,
						$scope.edit.schedule
					);
				} else if (type === 'projects') {
					$scope.edit.schedule.source.getProjects(
						processData,
						object,
						searchField,
						displayField,
						findCriteria,
						$scope.edit.schedule
					);
				} else if (type === 'resources') {
					$scope.edit.schedule.source.getResources(
						processData,
						object,
						searchField,
						displayField,
						findCriteria,
						$scope.edit.schedule
					);
				}

				//fbk.quickFind(type , processData, field, findCriteria);
			}

			function getContacts(
				callback,
				object,
				searchField,
				displayField,
				criteria
			) {
				//Convert type to source number because we use source numbers on our backend
				var sourceNumber = 1;
				var fileName = 'sc_relatedData' + sourceNumber + '.txt';
				var queryID = new Date().getTime(); //Used to create a unique id for our query
				var request = utilities.scriptURL(
					'script=' +
						encodeURIComponent('List Search From WebViewer') +
						'&$sourceNumber=' +
						sourceNumber +
						'&$searchCriteria=' +
						criteria +
						'&$queryID=' +
						queryID,
					queryID
				);
				utilities.getFileOnLoad(
					queryID,
					'sc_watcher.txt',
					fileName,
					processData
				);

				var listener = $rootScope.$on(queryID, function () {
					utilities.getFile(fileName, processData);
				});

				function processData(result) {
					listener();
					if (callback) {
						callback(csvData.csvToDataList(result));
					}
				}
			}

			function processData(data) {
				$scope.$evalAsync(function () {
					$scope.edit[type] = data;
					$scope.edit.searching = false;
					$scope.edit[type + 'LastFilterQuery'] = findCriteria;
				});
			}
		};

		//reset search list
		$scope.clearFilter = function ($event, type) {
			$scope.edit[type + 'Find'] = '';
			$scope.edit[type + 'LastFilterQuery'] = '';
			$scope.edit[type] = [];
		};

		$scope.eventAction = function (actionObj) {
			calendarIO.eventAction($scope.edit, actionObj);
		};

		function customFieldList(listText, delimiter) {
			if (!listText) {
				return [];
			}
			if (!delimiter) {
				delimiter = ',';
			}

			//Used so we can substitute our quoted delimiters. This way ',' for example will become ,
			var escapedDelimiter = "'" + delimiter + "'";
			var tempEscapedDelimiter = '!~escapedDelimiterSub~!';

			//Create an array from our list string and trim spaces
			var list = listText
				.replace(RegExp(escapedDelimiter, 'g'), tempEscapedDelimiter)
				.split(delimiter)
				.map(function (item) {
					return item
						.trim()
						.replace(RegExp(tempEscapedDelimiter, 'g'), delimiter);
				});

			return list;
		}

		function picklist(field) {
			$scope.picklistLoading = true;
			return new Promise((resolve, reject) => {
				var object;
				var fieldName;
				var i;
				var result = [];
				var schedule = $scope.edit.schedule;
				var arrayName = field === 'status' ? 'statuses' : 'resources';
				if (
					schedule.picklist &&
					schedule.picklist[field] &&
					schedule.picklist[field].picklist
				) {
					//we have a field picklist for this field saved to schedule
					for (i in schedule.picklist[field].picklist) {
						result.push({
							name: schedule.picklist[field].picklist[i].value,
						});
					}
				} else if (
					schedule.picklist &&
					schedule.picklist[field] === false
				) {
					//we know this field does not get a picklist, use global statuses
					$scope.picklistLoading = false;
					reject();
					return;
				} else if ($scope.edit.schedule.source.getPicklist) {
					// we're configured to look for field picklist, but we haven't checked yet.
					fieldName = $scope.edit.schedule.fieldMap[field];
					object = $scope.edit.schedule.objectName;

					$scope.edit.schedule.source.getPicklist(
						object,
						fieldName,
						schedule,
						processResult
					);
					return;
				} else {
					$scope.picklistLoading = false;
					reject();
					return;
				}
				function processResult(data) {
					$scope.$evalAsync(function () {
						if (data && data.list.length > 0) {
							for (i in data.list) {
								result.push({
									name: data.list[i].value,
									label: data.list[i].label,
								});
							}
							$scope.calendar[arrayName] = result;
							$scope.resourceObjects = angular.copy(
								$scope.calendar.resources
							);
							$scope.filteredResources = $scope.resourceObjects;
							$scope.picklistLoading = false;
							resolve($scope.filteredResources);
						} else {
							$scope.picklistLoading = false;
							reject();
						}
					});
				}
			});
		}

		function scheduleSelectionValid(selectedSchedule, compareSchedule) {
			if (!compareSchedule.editable || compareSchedule.externalEdits) {
				return false;
			}
			if ($scope.edit.unscheduled && !compareSchedule.allowUnscheduled) {
				return false;
			}

			return true;
		}

		function updateEditPopover(event) {
			//Only run if there is a popover being displayed
			calendarIO.updateEditEvent(event, $scope.edit);
			//We are closing the popover here if it is open because almost any change has the potential to change the position of the event. Close it just to be safe that it doesn't get mis-aligned
			$scope.popover.config.show = false;
		}

		function matchStatusColor(selected) {
			var statuses = seedcodeCalendar.get('statuses');

			for (var i = 0; i < selected.length; i++) {
				for (var ii = 0; ii < statuses.length; ii++) {
					if (selected[i] === statuses[ii].name) {
						return statuses[ii].color;
					}
				}
			}
		}

		function listClick(e, value, targetData, forceSingle, allowMultiple) {
			if (!value) {
				return [];
			}
			if (!targetData) {
				targetData = [];
			}

			var config = seedcodeCalendar.get('config');
			var output = JSON.parse(JSON.stringify(targetData)); //Clone our original data object so we don't mutate it with our changes
			for (var i = 0; i < targetData.length; i++) {
				if (value === targetData[i]) {
					output.splice(i, 1);
					return output;
				}
			}

			//We don't want to allow selecting a no filter label value and another item
			if (config.noFilterLabel === targetData[0]) {
				forceSingle = true;
			}

			if (
				(e.shiftKey || allowMultiple || environment.isMobileDevice) &&
				!forceSingle
			) {
				output.push(value);
			} else {
				output = [value];
			}

			return output;
		}

		function getTimeDuration() {
			return $scope.calendar.config.editTimeDuration === 'auto'
				? $scope.calendar.config.slotDuration
				: $scope.calendar.config.editTimeDuration;
		}

		function assignColor(status) {
			//Call assign event color to update our color swatch
			calendarIO.assignEventColor(
				$scope.edit.event,
				status,
				$scope.edit.schedule
			);
		}

		function onFieldSave(
			editEvent,
			fieldName,
			value,
			label,
			targetData,
			object,
			callback,
			clickEvent,
			revertFunction
		) {
			var selected =
				targetData && !targetData.id
					? !inArray(value, targetData)
					: null;
			var event = editEvent.event;
			var schedule = editEvent.schedule;
			var allActions = schedule.eventActions;
			var helpers = seedcodeCalendar.get('actionHelpers');
			var action;
			var runUpdate = true;
			var originalStatus = editEvent.status
				? JSON.stringify(editEvent.status)
				: null;
			for (var action in allActions) {
				if (
					allActions[action].type === 'onFieldChange' &&
					allActions[action].eventType &&
					allActions[action].eventType.editable
				) {
					var func = allActions[action].url;
					var preventDefault = allActions[action].preventDefault;
					var newWindow = allActions[action].newWindow;
					var actionObject = {};
					actionObject.callbacks = {};
					actionObject.callbacks.confirm = callback;
					actionObject.callbacks.cancel =
						revertFunction ||
						function () {
							callback(true);
						};
					actionObject.preventDefault = preventDefault;
					actionObject.newWindow = newWindow;
					var params = {};
					params.data = {};
					params.data.field = fieldName;
					params.data.value = value;
					if (selected === true || selected === false) {
						params.data.selected = selected;
					}
					if (label) {
						params.data.label = label;
					}
					if (object && schedule.sourceTypeID === 8) {
						params.data.layout = object;
					} else if (object) {
						params.data.objectName = object;
					}
					params.e = clickEvent;
					runUpdate = false;
					executeFunction(func, actionObject, helpers, params);
				}
			}
			//no actions, update field
			if (runUpdate) {
				callback();
			}

			function executeFunction(func, action, dbk, params) {
				var runCallback = true;
				if (func.indexOf('http') === 0) {
					if (action && action.newWindow) {
						window.open(func);
					} else {
						window.location = func;
					}
					if (action && action.preventDefault) {
						//do nothing
					} else if (
						action &&
						action.callbacks &&
						action.callbacks.confirm
					) {
						action.callbacks.confirm();
					}
				} else {
					//execute JS
					//run action
					var window = undefined;
					var angular = undefined;
					var Firebase = undefined;
					var firebase = undefined;
					var crypt = undefined;
					var calendarIO = undefined;
					try {
						eval(func);
					} catch (e) {
						runCallback = false;
						if (e instanceof SyntaxError) {
							dbk.showMessage(
								'Syntax Error: ' + e.message,
								0,
								8000,
								'error'
							);
						} else {
							dbk.showMessage(
								'Error: ' + e.message,
								0,
								8000,
								'error'
							);
						}
					}

					if (
						editEvent.status &&
						JSON.stringify(editEvent.status !== originalStatus)
					) {
						assignColor(editEvent.status);
					}

					if (runCallback) {
						if (action && action.preventDefault) {
							//do nothing, they can call action.callbacks.confirm in their action code.
						} else if (
							action &&
							action.callbacks &&
							action.callbacks.confirm
						) {
							action.callbacks.confirm();
						}
					}
				}
			}

			function inArray(value, theArray) {
				for (var i = 0; i < theArray.length; i++) {
					if (theArray[i] === value) {
						return true;
					}
				}
				return false;
			}
		}

		function deleteEvent(editEvent) {
			//Remove our confirm delete modal
			$scope.confirmDelete = false;

			calendarIO.deleteEvent(editEvent, function () {
				$timeout(function () {
					$scope.popover.config.show = false;
				}, 0);
			});
		}

		function matchArrayItem(value, array) {
			if (!array) {
				return;
			}
			for (var i = 0; i < array.length; i++) {
				if (value === array[i]) {
					return true;
				}
			}
		}

		function updateIsDayback(event, isDayback, tags) {
			event.isDayback = isDayback;
			if (isDayback) {
				addTag(tags, 'dayback');
			} else {
				removeTag(tags, 'dayback');
			}
		}

		function addTag(tags, name) {
			var tagPosition = tags.indexOf(name);
			if (tagPosition === -1) {
				tags.push('dayback');
			}
		}

		function removeTag(tags, name) {
			if (!tags) {
				return;
			}
			var tagPosition = tags.indexOf(name);
			if (tagPosition > -1) {
				tags.splice(tagPosition, 1);
			}
		}

		function getFieldHeight(isDayback) {
			return isDayback ? 100 : 0;
		}

		function applyInterventions(interventionObj) {
			if (!Array.isArray($scope.edit.interventions)) {
				$scope.edit.interventions = [];
			}
			$scope.edit.interventions.push(interventionObj);
		}

		//Can be used to determine if resource, status etc is empty
		function emptyItemArray(items) {
			var config = seedcodeCalendar.get('config');
			if (!items || !Array.isArray(items)) {
				return true;
			}

			for (var i = 0; i < items.length; i++) {
				if (
					items[i] !== null &&
					items[i] !== undefined &&
					items[i] !== '' &&
					items[i] !== config.noFilterLabel
				) {
					return false;
				}
			}
			return true;
		}

		//Linked event playback
		function playEvent() {
			var linkedEvents = $scope.edit.linkedEvents;
			linkedEvents.playback = true;
		}

		function pauseEvent() {
			var linkedEvents = $scope.edit.linkedEvents;
			linkedEvents.playback = false;
		}

		function navigateEvent(position) {
			var linkedEvents = $scope.edit.linkedEvents;
			var calendarElement = seedcodeCalendar.get('element');
			var arrayPosition;
			var adjustedArrayPosition;
			var scanCorrectionDirection;
			var targetSegment;
			var eventElement;
			var navigationDirection;
			var eventNavigationMap = linkedEvents.eventNavigationMap;

			if (!linkedEvents) {
				return;
			}

			if (position === 'first' || position === 'previous') {
				navigationDirection = -1;
			} else {
				navigationDirection = 1;
			}

			if (position === 'first') {
				arrayPosition = 0;
				scanCorrectionDirection = 1;
				if (linkedEvents.first) {
					return false;
				}
			} else if (position === 'previous') {
				arrayPosition = linkedEvents.position - 1;
				scanCorrectionDirection = 1;
				if (linkedEvents.first) {
					return false;
				}
			} else if (position === 'next') {
				arrayPosition = linkedEvents.position + 1;
				scanCorrectionDirection = -1;
				if (linkedEvents.last) {
					if (linkedEvents.playback) {
						// Last item in the play routine stop playback
						$scope.$evalAsync(function () {
							linkedEvents.playback = false;
							linkedEvents.playbackEnded = true;
						});
					}
					return false;
				}
			} else if (position === 'last') {
				arrayPosition = linkedEvents.segments.length - 1;
				scanCorrectionDirection = -1;
				if (linkedEvents.last) {
					return false;
				}
			}

			// adjustedArrayPosition = arrayPosition;
			targetSegment = linkedEvents.segments[arrayPosition];

			while (
				(!linkedEvents.navigation ||
					linkedEvents.navigation === navigationDirection) &&
				eventNavigationMap[targetSegment.event._id] &&
				arrayPosition >= 0 &&
				arrayPosition < linkedEvents.segments.length
			) {
				arrayPosition = arrayPosition + navigationDirection;
				targetSegment = linkedEvents.segments[arrayPosition];
			}

			if (targetSegment) {
				// eventElement = calendarElement.find(".fc-event-container [data-id='" + targetSegment._id + "']");
				eventElement = calendarElement.find(
					".fc-event-container [data-id='" +
						targetSegment.event._id +
						"'][data-instance='" +
						targetSegment.instance +
						"']"
				);

				// If we don't find the event in view then scan for a new event in the dom (could happen when skipping to first or last)
				if (!eventElement || !eventElement.length) {
					adjustedArrayPosition = arrayPosition;
					while (!eventElement || !eventElement.length) {
						adjustedArrayPosition =
							adjustedArrayPosition + scanCorrectionDirection;
						targetSegment =
							linkedEvents.events[adjustedArrayPosition];
						eventElement = calendarElement.find(
							".fc-event-container [data-id='" +
								targetSegment.event._id +
								"'][data-instance='" +
								targetSegment.instance +
								"']"
						);
					}
				}

				// Navigate to new linked event

				//Hide popover
				$scope.popover.config.show = false;
				if (environment.isPhone) {
					$timeout(function () {
						calendarIO.showEventPopover(
							targetSegment.event,
							eventElement,
							null,
							linkedEvents.playback,
							navigationDirection
						);
					}, 500);
				} else {
					calendarIO.showEventPopover(
						targetSegment.event,
						eventElement,
						null,
						linkedEvents.playback,
						navigationDirection
					);
				}

				return true;
			} else {
				linkedEvents.playback = false;
				return false;
			}
		}

		function openEditor(content, field, openIfEmpty) {
			var readOnly =
				!$scope.edit.editable || $scope.edit.readOnlyField[field];

			if (openIfEmpty && content) {
				return;
			}

			try {
				if (!SUNEDITOR) {
					utilities.showModal(
						'Rich Text Error',
						'There was a problem loading the rich text editor. If this problem persists you may need to close DayBack and try again.',
						'OK',
						null,
						null,
						null
					);
					return;
				}
			} catch (error) {
				utilities.showModal(
					'Rich Text Error',
					'There was a problem loading the rich text editor. If this problem persists you may need to close DayBack and try again.',
					'OK',
					null,
					null,
					null
				);
				return;
			}

			var popover = {
				id: 'richEditor',
				controller: 'ModalCtrl',
				container: $('body'),
				type: 'modal', // modal or popover
				width: 750,
				// positionX: e.pageX,
				// positionY: e.pageY,
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
				content: content,
				onChange: onChangeCallback,
			};
			var templateAdditions =
				'<div class="content-group">' +
				'<div class="content-container">' +
				'<div class="text-center">' +
				'<button translate type="submit" ng-click="close($event)" class="btn btn-sm btn-default form-control-btn dbk_button_default">Close</button>' +
				'</div>' +
				'</div>' +
				'</div>';

			var templateAdditionsMobile =
				'<div class="header-block dbk_contentDivider">' +
				'<span class="feedbacklink">' +
				'<a href="" ng-click="close($event)" class="header-block-content pull-left"><i class="fa fa-chevron-up fa-lg" style="vertical-align: -8%;"> </i><span class="pad-small" translate>Back</span></a>' +
				'</span>' +
				'</div>';

			utilities.popover(
				popover,
				'<div>' +
					(environment.isPhone ? templateAdditionsMobile : '') +
					'<rich-text-editor content="{{popover.config.content}}" read-only="' +
					readOnly +
					'"></rich-text-editor>' +
					(!environment.isPhone ? templateAdditions : '') +
					'</div>'
			);

			function onChangeCallback(newContent) {
				if (newContent === '<p><br></p>') {
					// Remove blank editor content
					newContent = '';
				}
				$scope.$evalAsync(function () {
					$scope.edit[field] = newContent;
				});
			}
		}
	}
})();
